.loginMarginTop1{
  padding-top: 12vh;
}

.loginMarginTop2{
  margin-top: 12vh;
  font-size: 40px;
}

.iingreso{
  font-size: 40px;
}

.txtBlack{
  color: black;
}

.loginMarginTop21{
  margin-top: 16px;
}

.txtPrimary{
  font-size: 32px;
}

.txtPrimary1{
  font-size: 32px;
  line-height: 8vh;
  color: #515C6F;
}

.txtSecondary{
  font-size: 12px;
  color: #515C6F;
}

.icon{
  width: 54 !important;
  height: 45 !important;
}


.loginImg{
  height: 14vh;
  width: 18vh;
}

.loginCenter{
  text-align: center;
  bottom: 0px;
}

.loginSing{
 color: #aaa;
 font-size: 18px;
}

.loginBtn{
  --background: #E66E5A;
  height: 8vh;
  width: 100%;
  font-size: 18px;
  --border-radius	:300px;
  margin-bottom: 48px;
  margin-top: 16px;
}


.marginaa{
  margin-top: 8vh;
  margin-left: 35vw;
  margin-right: 35vw;
  border-radius: 16px;
  padding: 32px;
  -webkit-box-shadow: 0px -5px 30px -20px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -5px 30px -20px rgba(0,0,0,0.75);
  box-shadow: 0px -5px 30px -20px rgba(0,0,0,0.75);
}