.productFormBack{
    background-color: #F0F4F8;
    border-radius: 8px;
    color: #748A9D;
    font-size: 14px;
    min-height: 50px;
    line-height: 50px;
    padding: 8px;
    padding-left: 16px;
}

.productFormBackOther{
    background-color: #F0F4F8;
    border-radius: 8px;
    color: #748A9D;
    font-size: 14px;
    min-height: 50px;
    line-height: 50px;
    padding-left: 16px;
}

.productFormBackOther2{
    background-color: #F0F4F8;
    border-radius: 8px;
    color: #748A9D;
    font-size: 14px;
    min-height: 50px;
    padding-left: 16px;
}

.productFormName{
    font-family: Helvetica;
    font-size: 22px !important;
    color: #286B67;
    min-height: 70px;
    line-height: 120% !important;

}

.productFormSuggested{
    --background: #F0F4F8;
    border-radius: 8px;
    --color: #748A9D;
    min-height: 50px;
    max-height: 50px;
    line-height: 50px;
    font-size: 14px;
}


.productFormBackDate{
    background-color: #F0F4F8;
    border-radius: 8px;
    color: #748A9D;
    font-size: 14px;
    min-height: 50px;
    max-height: 50px;
    line-height: 50px;
    padding-left: 16px;
}



.productFormSave{
    --background: #E66E5A;
    height: 8vh;
    width: 60%;
    font-size: 18px;
    --border-radius	:300px;
    margin-left: 20%;
}

.productFormPoli{
    color: #286B67;
    font-size: 24px;
    font-family: Helvetica;
    font-weight: bold;
}

.productFormImg{
    width: 125px;
    height: 125px;
    margin: auto;
}

input-wrapper {
    max-height: 40px !important;
    min-height: 40px !important;
}