.promotionName {
  color: #3e3f68;
  font-size: 12px;
  font-family: Helvetica;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.promotionDetail {
  color: #286b67;
  font-size: 10px;
  font-family: Helvetica;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.promotionImg {
  border-top-left-radius: 8px;

  border-top-right-radius: 8px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}