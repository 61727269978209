.productFormBack {
  background-color: #f0f4f8;
  border-radius: 8px;
  color: #748a9d;
  font-size: 14px;
  min-height: 50px;
  line-height: 50px;
  padding: 8px;
  padding-left: 16px;
}

.productFormBackOther {
  background-color: #f0f4f8;
  border-radius: 8px;
  color: #748a9d;
  font-size: 14px;
  min-height: 50px;
  line-height: 50px;
  padding-left: 16px;
}

.productFormName {
  font-family: Helvetica;
  font-size: 28px;
  color: #286b67;
  min-height: 70px;
}

.productFormSuggested {
  --background: #f0f4f8;
  border-radius: 8px;
  --color: #748a9d;
  min-height: 50px;
  max-height: 50px;
  line-height: 50px;
  font-size: 14px;
}

.productFormSuggested2 {
  --background: #f0f4f8;
  border-radius: 8px;
  --color: #748a9d;
  min-height: 50px;
  font-size: 14px;
}

.productFormBackDate {
  background-color: #f0f4f8;
  border-radius: 8px;
  color: #748a9d;
  font-size: 14px;
  min-height: 50px;
  max-height: 50px;
  line-height: 50px;
  padding-left: 16px;
}

.productFormSave {
  --background: #e66e5a;
  height: 8vh;
  width: 60%;
  font-size: 18px;
  --border-radius: 300px;
  margin-left: 20%;
}

.productFormPoli {
  color: #286b67;
  font-size: 24px;
  font-family: Helvetica;
  font-weight: bold;
}

.promotionFormInput {
  font-size: 12px;
}

.search {
  position: absolute;
  margin-top: -8px;
  max-height: 20vh;
  background-color: #fefefe;
  border-radius: 8px;
  z-index: 99;
  width: calc(100% - 10px);

  -webkit-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 1);
  box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 1);
}

.iterator {
  padding: 16px;
  border-top: solid #eee 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}
.itemTxt {
  color: #748a9d;
  font-family: Helvetica;
  font-size: 16px;
}

.itemTxt2 {
  line-height: 20vh;
  color: #748a9d;
  font-family: Helvetica;
  font-size: 16px;
}

.promotionFormImg {
  width: 15vw;
  height: 10vw;
  margin-left: 5vw;
}

.promotionFormName {
  margin-top: 2.5vw;
}

.promotionFormDescuento {
  font-family: Helvetica;
  font-size: 12px;
  color: #e66e5a;
}

.promotionFormTotal {
  font-family: Helvetica;
  font-size: 16px;
  color: #748a9d;
}

.promotionBackColor {
  border-radius: 8px !important;
  color: #a6bcd0;
  --background: #f0f4f8;
  margin-bottom: 0px;
  --border-color: #f0f4f8;
  font-family: "Helvetica";
  max-height: 80px;
}

.promotionFormTrash {
  width: 24px;
  height: 24px;
  margin-left: calc(50% - 12px);
  margin-top: calc(50%);
}

.promotionFormArea {
  background-color: #f0f4f8;
  border-radius: 8px;
}

.productFormPaddingCero {
  padding: 0px;
}
.productFormPaddingCero2 {
  padding: 0px 5px 0px 5px;
}

