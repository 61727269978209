.productsCbbIcon{
    width: 8px;
    height: 8px;
    float: right;
    margin-top: 8px;
    margin-right: 8px;
}

.productsCbbPresentation{
  max-height: 24px !important;
  border-radius: 10px !important;
  color: #748A9D;
  background-color: #F0F4F8;
  font-family: "Helvetica";  
  font-size: 12px;
  line-height: 24px;
  opacity: .7;
}

.productsImg{
    min-height: 6vw;
    max-height: 6vw;
}

.productsName{
    position: relative;
    color: #A6BCD0;
    font-size: 10px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productsContent{
    color: #A6BCD0;
    font-size: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.products{
    max-height: 72vh;
}

.products2{
    max-height: 90vh;
}

.productsImgSuggested{
    width: 25px;
    height: 25px;
    margin: auto;
    
}

.ProductFilterPolitica{
    width: 100%;
    height: 70%;
    --background: #E66E5A;
    --border-radius	:300px;
}

ion-toggle{
  
    --handle-background: #748A9D;
    --handle-background-checked: #fff;

    
    --background-checked: #fff;
  
    --handle-background-checked: #DB6152;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}