
.OrderList{
    background-color: #F0F4F8;
    border-radius: 4px;
    max-height: 32vh;
    min-height: 32vh;
}

.OrderListNotification{
    background-color: #BED2D1;
    margin-top: 2vh;
    border-radius: 4px;
}


.OrderListNotificationDiv{
    margin-top: 12px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 4px;
    border-radius: 4px;
    background-color: #F0F4F8;
    max-height: 23vh;
    min-height: 23vh;
}




.OrderDetail{
    max-height: 87vh;
    overflow-y: scroll;
}

.orderDetailSolicitud{
    margin-bottom: 8px;
    background-color: #E66E5A42;
    border-radius: 8px;
    margin-top: 8px;
}


.orderDetailSolicitudLista{
    margin-bottom: 8px;
    margin-top: 8px;
    background-color: #F0F4F8;
    border-radius: 8px;
}


.OrderFinishMarTop{
    margin-top: 11vh;
}

.orderDetailOneLine{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 10px;
    font-family: Helvetica;
}

.OrderDetIconDesAbajo{
    width: 12px;
    height: 12px;
    text-align: end;
    margin-top: calc(1.5vh - 6px);
}



.deliveryMethodOpc1{
    color: #748A9D;
    font-size: 16px;
    font-family: Helvetica;
    border-radius: 8px;
    background-color: #F0F4F8;
    height: 8vh;
    line-height: 7vh;
}


.deliveryOrderSelected{
    background-color: #E66E5A !important;
    color: #F4F6F8 !important;
}



.deliveryOrderDays{
    color: #748A9D;
    font-size: 14px;
    font-family: Helvetica;
    border-radius: 8px;
    background-color: #F0F4F8;
    height: 8vh;
    width: 100%;
    line-height: 8vh;
    float: left;
  }

  .orderDetailSolTxtRed{
      color: #DB6152;
      font-size: 14px;
      font-family: Helvetica;
  }

  .orderDetailTxt1{
    color: #DB6152;
    font-size: 36px;
    font-family: Helvetica;
  }

  .OrderDetailDivProduc{
      background-color: #F0F4F8;
      padding: 16px;
      border-radius: 8px;
      color: #748A9D;
      font-size: 16px;
      font-family: Helvetica;
  }

  
  .OrderBtn{
    --background: #E66E5A;
    height: 8vh;
    width: 100%;
    font-size: 18px;
    --border-radius: 300px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .OrderListBtn{
      
    --background: #E66E5A;
    height: 5vh;
    width: 100%;
    font-size: 18px;
    --border-radius: 300px;
    margin-top: 16px;
  }

  .orderFinishTxt{
    color: #748A9D;
    font-size: 18px;
    font-family: Helvetica;
  }
  .orderFinishTxt2{
    color: #748A9D;
    font-size: 14px;
    font-family: Helvetica;

  }

  .OrderNotificationTxt2{
      position: absolute;
      margin-top: -2vh;
      margin-left: 88%;
      color: #fff;
      border-radius: 50%;
      background-color: #DB6152;
      width: 5vh !important;
      height: 5vh !important;
      line-height: 4vh;
      font-size: 14px;
      font-family: Helvetica;
  }

  
.txtGreen{
    color: #286B67;
}

.orderTxtRed{
    color: #DB6152;
}

.solicitudesTxt0{
    font-size: 6px;
}

.popopo{
    font-size: 24px;
    font-family: Helvetica;
}



.btnEnviar{
    --background: #E66E5A;
    height: 8vh;
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
    --border-radius	:300px;
    margin-top: 16px;
}