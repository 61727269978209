.userCardCol{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 4px;
    padding-bottom: 0px;
    font-size: 12px;
}

.userCardCol{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 4px;
    padding-bottom: 0px;
    font-size: 12px;
}

.UserCardBackUser{
    background-color: #F0F4F8;
    border-radius: 4px;
    max-height: 38vh;
    min-height: 38vh;
}


.UserCardBackUser2{
    color: #DB6152
}

.UserCardBackNotification{
    background-color: #BED2D1;
    margin-top: 2vh;
    border-radius: 4px;
    padding-bottom: 8px;
}

.UserCardNotificationTxt{
    color: #286B67;
    line-height: 4vh;
}

.UserCardNotificationTxt2{
    position: absolute;
    margin-top: -1.5vh;
    margin-left: 85%;
    color: #fff;
    border-radius: 50%;
    background-color: #DB6152;
    width: 5vh !important;
    height: 5vh;
    line-height: 4vh;
    font-size: 14px;
    font-family: Helvetica;
}


.UserCardBackNotificationDiv{
    margin-top: 12px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4px;
    padding-bottom: 12px;
    border-radius: 4px;
    background-color: #F0F4F8;
    max-height: 30vh;
    min-height: 30vh;
}



.UserCardUserPresentation{
    position: absolute;
    width: 70%;
    margin-left: 15%;
    height: 10vh;
    background-color: #F0F4F8;
    border-radius: 8px;
}

.UserCardUserImg{
    width: 10vh;
    height: 10vh;
    margin-left: -5vh;
}
.UserCardUserTxt{
    margin-top: -8vh;
    margin-left: 15%;
    width: 80%;
    color: #748A9D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.UserCardUserTxtBig1{
    margin-top: -8vh;
    margin-left: 15%;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 42px;
    color: #286B67;
}

.UserCardUserTxtBigRojo{
    color: #DB6152;
}


.userCardUserMarginTop{
    margin-top: 13vh;
    margin-left: 7%;
    margin-right: 12%;
}

.userCardUserValores{
    color: #748A9D;
    font-size: 12px;
    font-family: Helvetica;
    margin-top: 10;
}

.userCardUserDocTxt{
    line-height: 3.5vw;
}


.userCardUserDocImg{
    width: 2.5vw;
    height: 3vw;
    margin-top: 4px;
    margin-left: calc(50% - 1.5vw);
}


.UserCardTxtProduct{
    line-height: 1.5vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
}

.UserCardCantProduct{
    float: left;
    line-height: 3vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

.floatLeft{
    position: absolute;
    margin-top: -20vw;
    margin-left: 52%;

}

.UserCardTotal{
    font-size: 24px;
    font-family: Helvetica;
    color: #748A9D;
}


.UserCardTotalValor{
    margin-top: 8px;
    font-size: 34px;
    font-family: Helvetica;
    color: #DB6152;
}

.CardUserProductsBack{
    
    background-color: #F0F4F8;
}

.UserCardNoPadding{
    padding: 0px;
}

.UserCarScrol2{
    margin-top: 14vh;
    max-height: 75vh;
}

.UserCardUserOpc{
    width: 65%;
    margin-left: 17.5%;
    height: 10vh;
    border-radius: 8px;
    background-color: #F0F4F8;
}

.UserCardPositionTotal{
    margin-left: 55%;
    margin-bottom: -11vh;
}

.UserCardtxtTiendas{
    font-size: 34px;
    font-family: Helvetica;
    color: #DB6152
}

.UserCardTxtDoc{
    color: #286B67;
    font-family: Helvetica;
    font-size: 24px;
}

.rosa{
    background-color: #E66E5A42
}
.gris{
    background-color: #F0F4F8;
}


.UserCarScrol3{
    max-height: 75vh;
}

.UserCardSinProductos{
    padding: 16px;
    margin-bottom: 8vh;
    font-size: 24px;
    font-family: Helvetica;
    color: #748A9D;
}

.UserCardDocBackRojo{
    background-color: #E66E5A42;
    border-radius: 8px;
}


.UserCardDocBack{
    background-color: #F0F4F8;
    border-radius: 8px;
}

.UserCardTiendaName{
    font-size: 16px;
    color: #748A9D;
    font-family: Helvetica;
}

.UserCardContainerStore{
    background-color: #F0F4F8;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 8px;
}

.UserCardTiendaDatos{
    font-size: 12px;
    color: #748A9D;
    font-family: Helvetica;
}

.minMap{
    min-height: 270px;
}


.saveMap{
    --background: #E66E5A;
    height: 8vh;
    width: 100%;
    font-size: 18px;
    --border-radius	:300px;
    margin-top: 16px;
}

.IconDesAbajo{
    width: 12px;
    height: 12px;
    text-align: end;
    margin-top: calc(3vh - 6px);
}


.orderBox{
    background-color: #F0F4F8;
    border-radius: 8px;
    margin-top: 4px;
}

.orderRow{
    color: #748A9D;
    font-size: 16px;
    font-family: Helvetica;
}

.orderRowProduct{
    font-size: 14px;
}

.lineHe{
    font-size: 16px;
    height: 7vh;
    line-height: 6vh;
}

.orderMargin8{
    margin: 8px;
}

.modal-wrapper.sc-ion-modal-md{
    min-width: 65vw !important;
    min-height: 90vh !important;
    background-color: #F0F4F8;
}

.gridModal{
    min-height: 90vh;
    max-height: 90vh;
    align-items: center;
    overflow-y: scroll;
}

.modalDownload{
    width: 60%;
    height: 5vh;
    line-height: 5vh;
    margin-left: 20%;
    border-radius: 8px;
    border: solid 1px #286B67;
    text-align: center;
    color: #286B67 ;
    font-size: 14px;
    font-family: Helvetica;
}

.ModalIcon{
    width: 4vh;
    height: 4vh;
    margin-top: .5vh;
}



::-webkit-scrollbar {
    width: 10px;
}
  
::-webkit-scrollbar-thumb {
    background: #DB615252; 
    border-radius: 10px;
}

  
::-webkit-scrollbar-thumb:hover {
    background: #DB615252; 
}

.UserCardBtnSave{
    margin: auto;
}

.btnRefresh{
    width: 100%;
    height: 100%;
}

.bllack{
    color: #2C4841;
}

.txtGreen{
    color: #286B67;
}

.border02{
    border: solid 1px #DB615252;
}