.notificationCenterImg{
    margin: auto
}

.CalendarFormBack{
    --background: #F0F4F8;
    border-radius: 8px;
    --color: #748A9D;
    font-size: 14px;
}

.calendarFormTxt{
    font-size: 24px;
    text-align: center;
}