
.App {
    font-family: sans-serif;
    text-align: center;
    height: 37vh;
  }
  
  .App-map {
    height: 30vh;
    width: 100%;
  }

  
  .App-map2 {
    height: 80vh;
    width: 98%;
    margin-left: 1%;
  }