.notificationTxt{
    color:#286B67;
    font-size: 16px;
    font-family: Helvetica;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.notifications{
    max-height: 90vh;
}