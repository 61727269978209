.containerCardinalidadForm{
    padding: 0px 16px;
    margin-top: 40px;
}

.containerCardinalidadForm2{
    
    border-radius: 20px;
    -moz-box-shadow:0px 1vw 10px 0px rgba(222,222,222,.5);
    box-shadow: 0px 1vw 20px 0px rgba(190,190,190,.5);
}

.CardinalidadSave{
    --background: #E66E5A;
    height: 40px;
    width: 20%;
    font-size: 18px;
    --border-radius	:300px;
    margin: 20px 0 20px 40%;
}

.containerCardinalidadList{
    padding: 0px 16px;
    margin-top: 40px;
}