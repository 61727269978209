.borderBottom{
  border-bottom: 2px solid #F0F4F8;
}

.backColor{
  border-radius: 8px !important;
  color: #A6BCD0;
  --background: #F0F4F8;
  margin-bottom: 0px;
  --border-color: #F0F4F8;
  font-family: "Helvetica";
  max-height: 50px;
}


ion-item {
  --highlight-color-valid: transparent;
  --highlight-color-invalid: transparent;
  --highlight-color-focused: var(--ion-color-light);
  font-size: 14px;
}



.inputIcon{
  margin-top: 12.5px;
  width: 22px;
  height: 22px; 
}
.inputIcon2{
  margin-top: 12.5px;
  width: 22px;
  height: 22px; 
}

.iconCbb{
  width: 24px;
  height: 24px; 
}

.inputIconOnly{
  margin-top: 4vw;
  margin-left: .5vw;
  min-width: 2vw;
  min-height: 2vw; 
}