.headerBack{
    height: 7vh;
    background-color: #D7D3AE1A ;
    padding: -16px;
}

.headerIconMariginTop{
    margin-top: calc( 2vh / 2 );
}
.headerIconMariginTop2{
    margin-top: calc( (2vh / 2) + 4px );
}

.headerLineHeight7{
    line-height: 7vh;
}

.gridNoPadding{
    padding: 0px;
}

.headerIcon{
    width: 4vh;
    height: 4vh;
}
.headerIcon2{
    width: 4vh;
    height: 4vh;
    margin-top: calc( 2vh / 2 );
}

.headerLogo{
    margin-top: -4px;
    width: 8vh;
    height: 8vh;
    margin-left: calc(50% - 4vh);
}

.headerTitulo{
    font-size: 34px;
    font-family: 'milkshake';
    color:#2C4841
}

.logOut{
    
    margin-top: 1.5vh;
    width: 4vh;
    height:4vh;
}